import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'

const ReleaseItem = ({
  date,
  title,
  short_desc,
  featuredimage,
  featured = false,
  slug = 'notfound'
}) => {
  return (
    <div
      className={featured ? 'beforewrapper' : ''}
      onClick={() => {
        navigate(`/release/${_.kebabCase(slug || title)}/`)
      }}
    >
      <div
        className={`releaseItem__wrap ${
          featured ? 'releaseItem__wrap--featured' : 'releaseItem__wrap--normal'
        }`}
      >
        <div
          className={`releaseItem ${
            featured ? 'releaseItem--featured' : 'releaseItem--normal'
          }`}
        >
          <img
            className="releaseItem__img"
            src={featuredimage.image}
            alt={featuredimage.alt}
          />
          <div className="releaseItem__meta">
            <p className="releaseItem__date"> {date} </p>
            <h1 className="releaseItem__title"> {title} </h1>
            <p className="releaseItem__shortDesc">
              {' '}
              {short_desc?.substring(0, featured ? 250 : 115)}{' '}
              {short_desc.split('').length > (featured ? 250 : 115) && '...'}
            </p>
            <a
              href={`/release/${_.kebabCase(slug)}/`}
              className="releaseItem__readmore"
            >
              Read More ...
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReleaseItem
