import React, { useState } from 'react'
import Paragraph from '../../shared/Paragraph'

import PrimaryHeading from '../../shared/PrimaryHeading'

function HeroSectionTwo({
  title = 'NO Title',
  description = 'no description'
}) {
  return (
    <section className="heroSectionTwo">
      <div className="heroSectionTwo__wrapper">
        <PrimaryHeading headingText={title} />
        <div className="heroSectionTwo__body">
          <Paragraph paragraphText={description} alignCenter={true} />
        </div>
      </div>
    </section>
  )
}

export default HeroSectionTwo
