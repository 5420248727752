import React from 'react'

function Paragraph({
  textColorClass,
  paragraphText,
  customClass,
  alignCenter = false
}) {
  return (
    <p
      className={`primaryParagraph--${
        textColorClass ? textColorClass : 'primary'
      } primaryParagraph ${customClass} ${
        alignCenter ? 'primaryParagraphCenter' : ''
      }`}
    >
      {paragraphText}
    </p>
  )
}

export default Paragraph
