import { nanoid } from 'nanoid'
import React from 'react'
import { Col, Row } from 'react-grid-system'
import ReleaseItem from '../ReleaseItem'

const rightSideChevronSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 512 512"
  >
    <path
      fillRule="nonzero"
      d="M0 256c0 70.68 28.66 134.7 74.98 181.02C121.3 483.34 185.32 512 256 512c70.69 0 134.7-28.66 181.02-74.98C483.34 390.7 512 326.69 512 256c0-70.69-28.66-134.7-74.98-181.02C390.7 28.66 326.69 0 256 0 185.32 0 121.3 28.66 74.98 74.98 28.66 121.3 0 185.31 0 256zm231.67-109.04L340.7 256 231.67 365.04l-40.52-40.51 68.51-68.52-68.52-68.52 40.53-40.53zM101.01 410.99c-39.66-39.66-64.2-94.47-64.2-154.99 0-60.53 24.54-115.33 64.2-154.99 39.66-39.66 94.47-64.2 154.99-64.2 60.53 0 115.33 24.54 154.99 64.2 39.66 39.66 64.2 94.46 64.2 154.99 0 60.53-24.54 115.33-64.2 154.99-39.66 39.66-94.46 64.2-154.99 64.2-60.52 0-115.33-24.54-154.99-64.2z"
    />
  </svg>
)

const ReleaseItemAccordion = ({
  title = 'For Year',
  subTitle = '2022',
  releaseItems = []
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false)

  return (
    <div className="releaseItemAccordion">
      <h1 className="releaseItemAccordion__title">
        {title} <span> {subTitle}</span>{' '}
        <span
          onClick={() => setIsCollapsed((prev) => !prev)}
          className={`releaseItemAccordion__icon ${
            isCollapsed
              ? 'releaseItemAccordion__icon--down'
              : 'releaseItemAccordion__icon--up'
          }`}
        >
          {rightSideChevronSvg}
        </span>
      </h1>
      <div
        className={`releaseItemAccordion__rowcollapser ${
          isCollapsed
            ? 'releaseItemAccordion__rowcollapser--collapsed'
            : 'releaseItemAccordion__rowcollapser--open'
        }`}
      >
        <Row>
          {releaseItems?.map((node) => (
            <Col key={nanoid()} lg={4} md={6} sm={12}>
              <ReleaseItem {...node.frontmatter} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}

export default ReleaseItemAccordion
