import React from 'react'

function SecondaryHeading({ primaryText, secondaryText, backcroundTextClass }) {
  return (
    <h2 className={`SecondaryHeading`}>
      <span
        className={`background__text  background__text--${
          backcroundTextClass || ''
        }`}
      >
        {primaryText}
      </span>

      {secondaryText}
    </h2>
  )
}

export default SecondaryHeading
